export const faGeorgiaTech = {
    prefix: "fab",
    iconName: "georgiatech",
    icon: [
      1300,
      1300,
      [],
      "e001",
      "M644.154 329.253v262.558h146.396l14.321-74.789h45.616c-27.051 113.51-152.231 188.83-299.157 177.16-139.5-10.608-289.079-89.641-288.549-237.098 0.53-162.839 146.396-256.723 282.714-262.558 91.232-3.713 193.603 31.295 267.332 92.293 4.243 3.713 5.304 4.243 5.835 4.774s1.061 0.53 4.774 3.713l4.243 3.183h162.839v-291.201h-169.204v81.154c-68.955-40.312-178.221-79.033-303.931-78.502-143.744 0.53-283.775 59.407-384.555 161.248-82.215 83.806-126.24 185.647-125.179 288.018 1.061 100.249 46.677 196.255 127.301 269.984 102.902 93.884 253.541 142.683 402.058 130.483 115.632-9.548 218.533-35.008 293.853-72.668v28.643h179.282v-298.096h42.964v331.513h-85.928v169.204h397.285v-169.204h-85.928v-332.043h181.404l13.261 74.789h146.926v-262.558h-975.973z"
    ]
  };